<template>
  <div>
    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center justify-content-center">
        <b-col cols="12">
          <h2>{{ $t('suporte.titulo') }}</h2>
          <b-card-text class="mb-3">
            {{ $t('suporte.subtitulo') }}
          </b-card-text>
        </b-col>

        <!-- Card de contato pelo WhatsApp -->
        <b-col
          v-if="empresa.linkWhatsapp || empresa.suporteTelefone"
          sm="6"
          class="d-flex flex-column justify-content-between"
        >
          <b-card class="shadow-none py-1 faq-contact-card h-100">
            <b-avatar
              v-if="empresa.linkWhatsapp || empresa.suporteTelefone"
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
              :src="require('@/assets/images/icons/whatsapp.png')"
            />
            <h4 v-if="empresa.suporteTelefone">
              {{ empresa.suporteTelefone }}
            </h4>
            <h4 v-if="empresa.linkWhatsapp">
              <b-link
                :href="encodeURI(empresa.linkWhatsapp.replace('$$nome$', userData.displayName))"
                target="_blank"
              >
                {{ $t('suporte.cliqueaqui') }}
              </b-link>
            </h4>
          </b-card>
        </b-col>

        <!-- Card de contato pelo Telegram -->
        <b-col
          v-if="empresa.suporteTelegram"
          sm="6"
          class="d-flex flex-column justify-content-between"
        >
          <b-card class="shadow-none py-1 faq-contact-card h-100">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
              :src="require('@/assets/images/icons/telegram.png')"
            />
            <h4>
              @{{ empresa.suporteTelegram }}
            </h4>
            <h4>
              <b-link
                :href="'https://t.me/' + empresa.suporteTelegram"
                target="_blank"
              >
                {{ $t('suporte.cliqueaqui') }}
              </b-link>
            </h4>
          </b-card>
        </b-col>

        <!-- Texto centralizado -->
        <b-col
          cols="12"
          class="text-center"
        >
          <span class="text-body">{{ $t('suporte.textofinal') }}</span>
        </b-col>
      </b-row>
    </section>

    <!--/ contact us -->

  </div>
</template>

<script>
import {
  BCard, BCardText, BAvatar, BLink, BCol, BRow,
} from 'bootstrap-vue'
import utilsMixin from '@/mixins/utilsMixin'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BLink,
    BCol,
    BRow,
  },

  mixins: [utilsMixin],

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      data: {},
    }
  },

  setup() {
    const { empresa } = useAppConfig()
    return {
      empresa,
    }
  },

  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
